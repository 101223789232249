import React from "react"
import { Link } from "gatsby"
import starIcon from "../../assets/images/star-icon.png"

const OurSolutions = () => {
  return (
    <section className="solutions-area pb-70">
      <div className="container">
        <div className="section-title">
          <h2>
            Why We Are <span style={{ color: "#70DA44" }}>Different</span>?
          </h2>
          <p>
            Most outsourcing company will leave their talents into the client's
            hands once hired, but software is an evolving industry with new
            trends and practices that are updated frequently. That is why we
            provide continuing education through tech talks and conference for
            our talents. We focus on the quality of talents that we provide and
            provide <strong>FREE</strong> consultancy.
          </p>
        </div>

        <div className="row">
          <div className="col-lg-4 col-sm-6">
            <div className="single-solutions-box">
              <div className="icon">
                <i className="flaticon-user"></i>
              </div>
              <h3>
                <Link to="/service-details">Talk to our experts</Link>
              </h3>
              <p>
                Have a meeting with us and let's talk about what talent you need
              </p>
            </div>
          </div>

          <div className="col-lg-4 col-sm-6">
            <div className="single-solutions-box">
              <div className="icon">
                <i className="flaticon-laptop"></i>
              </div>

              <h3>
                <Link to="/service-details">Finding the right candidate</Link>
              </h3>

              <p>
                We will find your new future team members
                from our existing pool of talent, or search
                wider if needed.
              </p>
            </div>
          </div>

          <div className="col-lg-4 col-sm-6 offset-lg-0 offset-sm-3">
            <div className="single-solutions-box">
              <div className="icon">
                <i className="flaticon-money"></i>
              </div>

              <h3>
                <Link to="/service-details">Satisfaction Guaranteed</Link>
              </h3>

              <p>
                We stand by our promise. If you don't love
                the team, we will refund you - no hard feelings!
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default OurSolutions
